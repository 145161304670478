<template>
  <div
    class="flex flex-col h-full max-w-sm rounded overflow-hidden shadow-lg mb-6"
  >
    <vueper-slides :arrows="false">
      <vueper-slide
        v-for="image in category.gallery"
        :key="image.id"
        :image="image.image"
      />
    </vueper-slides>
    <!-- <img class="w-full" :src="category.gallery[0].image" :alt="category.name" /> -->
    <div class="px-6 py-4 flex-1">
      <div class="font-bold text-xl mb-2">{{ category.name }}</div>
      <p class="text-gray-700 text-base" v-html="category.description" />
    </div>
    <div class="px-6 py-4 text-right">
      <router-link
        :to="{ name: 'categories.detail', params: { category: category.url } }"
        class="text-sm bg-transparent hover:bg-teal-500 text-teal-700 hover:text-white py-2 px-4 border border-teal-500 hover:border-transparent rounded"
      >
        Ver proyectos...
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "category-detail",
  props: {
    category: {
      type: Object,
      required: true
    }
  }
};
</script>
