<template>
  <div>
    <h3
      class="font-sans font-bold tracking-wider uppercase text-4xl mb-10 text-center"
    >
      {{ content.title }}
    </h3>

    <div class="font-sans mb-6" v-html="content.content"></div>
  </div>
</template>

<script>
export default {
  name: "content.block",
  props: {
    content: {
      type: Object,
      required: true
    }
  }
};
</script>
