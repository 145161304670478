<template>
  <div>
    <h3 class="font-sans font-bold tracking-wider uppercase text-4xl mb-10">
      {{ category.name }}
    </h3>

    <vueper-slides
      class="no-shadow mb-6"
      :visible-slides="3"
      slide-multiple
      :gap="3"
      :slide-ratio="1 / 4"
      :dragging-distance="200"
      :breakpoints="{ 800: { visibleSlides: 1, slideMultiple: 1 } }"
    >
      <vueper-slide
        v-for="image in category.gallery"
        :key="image.id"
        :image="image.image"
      />
    </vueper-slides>

    <div class="font-sans text-base mb-10" v-html="category.description" />

    <h3 class="font-sans font-bold tracking-wider uppercase text-2xl mb-10">
      Proyectos
    </h3>

    <detail
      class="mb-6"
      v-for="project in projects"
      :key="project.id"
      :project="project"
      :openedGallery.sync="openedGallery"
      @open-gallery="openGallery"
    />
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import Detail from "@/components/project/Detail.vue";

export default {
  name: "categories.detail",
  data() {
    return {
      openedGallery: 0
    };
  },
  async mounted() {
    let slug = this.$route.params.category;

    if (!this.category.url !== slug) {
      await this.fetchCategory(slug);
    }

    if (this.projects.length === 0) {
      await this.fetchProjects(this.category.id);
    }
  },
  components: {
    Detail
  },
  computed: {
    ...mapState("categories", {
      categoriesProcessing: state => state.processing,
      category: state => state.category
    }),
    ...mapState("projects", {
      projectsProcessing: state => state.processing,
      projects: state => state.projects
    }),
    ...mapGetters("categories", ["getCategoryBySlug"])
  },
  methods: {
    ...mapActions("categories", ["fetchCategory"]),
    ...mapActions("projects", ["fetchProjects"]),
    openGallery(id) {
      this.openedGallery = id;
    }
  }
};
</script>
