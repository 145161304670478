<template>
  <div>
    <div class="w-full lg:flex">
      <div
        class="h-48 lg:h-auto lg:w-48 flex-none bg-cover rounded-t lg:rounded-t-none lg:rounded-l text-center overflow-hidden"
        :style="{ backgroundImage: 'url(' + project.image + ')' }"
        title="Woman holding a mug"
      ></div>
      <div
        class="w-full border-r border-b border-l border-grey-light lg:border-l-0 lg:border-t lg:border-grey-light bg-white rounded-b lg:rounded-b-none lg:rounded-r p-4 flex flex-col justify-between leading-normal"
      >
        <div class="mb-8">
          <p class="text-sm text-grey-dark flex items-center">
            {{ project.owner }}
          </p>
          <div class="text-black font-bold text-xl mb-2">
            {{ project.name }}
          </div>
          <p class="text-grey-darker text-base" v-html="project.description" />
        </div>

        <div>
          <button
            v-if="openedGallery !== project.id"
            @click="$emit('open-gallery', project.id)"
            class="text-sm bg-transparent hover:bg-teal-500 text-teal-700 hover:text-white py-2 px-4 border border-teal-500 hover:border-transparent rounded"
          >
            Mostrar galería
          </button>
          <button
            v-else
            @click="$emit('open-gallery', 0)"
            class="text-sm bg-teal-500 hover:bg-teal-500 text-white py-2 px-4 border border-teal-500 hover:border-transparent rounded"
          >
            Ocultar galería
          </button>
        </div>
      </div>
    </div>

    <vueper-slides
      v-if="openedGallery === project.id"
      class="no-shadow my-6"
      :arrowsOutside="false"
      :visible-slides="2"
      slide-multiple
      :gap="2"
      :slide-ratio="1 / 4"
      :dragging-distance="200"
      :breakpoints="{ 800: { visibleSlides: 1, slideMultiple: 1 } }"
    >
      <vueper-slide
        v-for="image in project.gallery"
        :key="image.id"
        :image="image.image"
      />
    </vueper-slides>
  </div>
</template>

<script>
export default {
  name: "project.detail",
  props: {
    project: {
      type: Object,
      required: true
    },
    openedGallery: {
      type: Number,
      default: 0
    }
  }
};
</script>
