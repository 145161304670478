<template>
  <div class="about">
    <block v-for="content in contents" :key="content.id" :content="content" />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Block from "@/components/content/Block.vue";

export default {
  name: "about",
  async mounted() {
    if (this.contents.length === 0) {
      await this.fetchContents();
    }
  },
  components: {
    Block
  },
  computed: {
    ...mapState("content", {
      processing: state => state.processing,
      contents: state => state.contents
    })
  },
  methods: {
    ...mapActions("content", ["fetchContents"])
  }
};
</script>
