<template>
  <div class="home">
    <div class="mb-6" v-html="title" />
    <div class="grid grid-cols-3 gap-4">
      <category-detail
        v-for="category in categories.slice(0, 3)"
        :key="category.id"
        :category="category"
      />
    </div>

    <div class="py-10 text-center">
      <router-link
        :to="{ name: 'projects' }"
        class="bg-teal-900 hover:bg-teal-500 text-white font-semibold hover:text-white py-2 px-4 rounded"
      >
        Conocer más proyectos
      </router-link>
    </div>

    <div class="mb-6" v-html="subTitle" />
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import CategoryDetail from "@/components/category/Detail.vue";

export default {
  name: "home",
  data() {
    return {
      title: null,
      subTitle: null
    };
  },
  async mounted() {
    if (this.categories.length === 0) {
      await this.fetchCategories();
    }

    if (this.content.length === 0) {
      await this.fetchCustomContent();
    }

    this.title = this.getCustomContentByType("proyectos").content;
    this.subTitle = this.getCustomContentByType("descripcion-empresa").content;
  },
  components: {
    CategoryDetail
  },
  computed: {
    ...mapState("categories", {
      categoriesProcessing: state => state.processing,
      categories: state => state.categories
    }),
    ...mapState("content", {
      customProcessing: state => state.processing,
      content: state => state.customContent
    }),
    ...mapGetters("content", ["getCustomContentByType"])
  },
  methods: {
    ...mapActions("categories", ["fetchCategories"]),
    ...mapActions("content", ["fetchCustomContent"])
  }
};
</script>
